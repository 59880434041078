.carousel-container {
    width: 85%;
    display: flex;
    margin-top: 3.125em;
}

.carousel-images {
    width: 100%;
    height: 100%;
    border-radius: 1.5625em;
    overflow: hidden;
}

.arrow-button {
    background: none; 
    border: none; 
    cursor: pointer;
}

.arrow-button img{
    width: 3.125em;
}

.arrow-button:active {
    filter: brightness(85%);
}

@media screen and (max-width: 768px) {
    .carousel-container {
        width: 100%;
        margin-top: 1em;
    }

    .arrow-button img{
        width: 1.5em
    }
}
 