@import url("https://use.typekit.net/cpf2ulf.css");

* {
 margin: 0;
}

.home-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.paragraphe-basique {
    font-family: 'Calibri', sans-serif;
    font-size: 1.75em;
    font-weight: bold;
    margin: 2em auto;
    text-align: center;
}

.cursive {
    font-family: "modern-love", sans-serif;
    font-weight: bold;
    font-size: 1em;
}

.titre-home {
    font-family: 'modern-love', sans-serif;
    font-weight: bold;
    font-size: 2em;
    margin-top: 2em;
    text-align: center;
}

.titre-home-cursive {
    font-family: 'modern-love', sans-serif;
    font-weight: bold;
    font-size: 2em;
    margin-top: 1.5em;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .home-container {
        width: 100%;
    }

    .paragraphe-basique {
    font-size: 1em;
    margin: 1.5em;
    }

    .titre-home {
    font-size: 1.5em;
    }

    .titre-home-cursive {
    font-size: 1.5em;
    }

}
 