.producteurs-articles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
}

.producteur-container-articles {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: auto;
    flex-wrap: wrap;
}

// PRODUCTEUR CARD //

.producteur-container {
    width: 300px;
    height: 300px;
    border: solid 0.25em #f0c8c5;
    border-radius: 0.75em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    padding-bottom: 2em;
    background-color: white;
    cursor: pointer;
    transition: height 0.8s ease;
}

.producteur-container:hover {
    background-color: #f0c8c5;
    border: solid 0.25em #e9afaf;
}

.producteur-photo-nom{
    width: 100%;

}

.producteur-photo {
    filter: grayscale(20%) ;
    transition: filter 0.8s ease;
}

.producteur-photo img{
    width: 100%;
    height: 200px;
    border-radius: 0.25em;
    object-fit: cover;
    transition: filter 0.8s ease;
}

.admin-producteur-photo img{
    width: 270px;
    height: 185px;
    border-radius: 0.25em;
    object-fit: cover;
    transition: filter 0.8s ease;
}

.producteur-nom {
    font-family: 'Modern-love', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 0;
    text-align: center;
}

.producteur-adresse-site {
    padding-top: 1em;
    transform: scaleY(0);
    height: 0;
    opacity: 0;
    transition: transform 0.8s ease, opacity 0.5s ease;
}

.producteur-adresse {
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
}

.producteur-site {
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    margin: 0.5em 0;
}

.extended {
    background-color: #f0c8c5;
    border: solid 0.25em #e9afaf;
    height: 400px;
    transition: height 0.8s ease;
}

.extendedDetails {
    transform: scaleY(1);
    opacity: 1;
    transform-origin: top;
    transition: opacity 0.5s ease, transform 0.8s ease;
}

.grayScale {
    filter: grayscale(0%);
    transition: filter 0.8s ease;
}

.admin-producteur-nom{
    font-family: 'Modern-love', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 0;
    text-align: center;
}

.admin-producteur-nom-input input{
    width: 100%;
    font-family: 'Modern-love', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.admin-producteur-adresse-site{
    padding-top: 1em;
}

.admin-producteur-adresse{
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
}

.admin-producteur-site{
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    margin: 0.5em 0;
}

// ARTICLE CARD //

.producteur-articles {
    transform: scaleX(0);
    opacity: 0;
    width: 0px;
    height: 0px;
    border: solid 0.25em #f0c8c5;
    border-radius: 0.75em;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    background-color: white;
    cursor: pointer;
}

.extendedArticles {
    transform: scaleX(1);
    opacity: 1;
    height: 400px;
    width: 768px;
    transform-origin: left;
    transition: transform 0.8s ease, opacity 0.6s ease, height 0.8s ease;
}

//****** MEDIA QUERIES ******//
@media screen and (max-width: 768px) {

    .producteur-container-articles {
        flex-direction: column;
    }

    .producteurs-articles {
        display: flex;
        flex-direction: column;
    }

    .producteur-articles {
        transform: scaleY(0);
        transform: scaleX(0);
        width: 280px;
        height: 0px;
        display: none;
    }

    .extendedArticles {
        transform-origin: top;
        transform: scaleY(1);
        transform: scaleX(1);
        height: auto;
        display: initial;
    }

    .producteur-container:hover {
        background-color: white;
    }

    .extended:hover {
        background-color: #f0c8c5;
    }
}