.articles-page {
    width: 90%;
    display: flex;
    margin: 0 auto;
    min-height: 35vw;
}

.article {
    width: 200px;
}

.articles__div {
    margin-left: 1em;
    margin-top: 2em;
    width: 80%;
}

.article-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.all-article {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
}

.filtre__h1 {
    font-family: 'modern-love', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}
  
.filtre-article__div {
    max-width: 15.625em;
    display: flex;
    flex-direction: column;
    padding: 1em;
    margin-top: 1em;
    z-index: 1;

}

.filtre-categorie-vrac {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 1em 0;
    border-top: #f0c8c5 solid 0.125em;
    border-bottom: #f0c8c5 solid 0.125em;
    box-sizing: border-box;
}

.filtre-categorie {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
}

.filtre-vrac-local {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    justify-content: space-between;
}

.filtre-vrac {
    display: flex;
    gap: 2em;
    align-items: center;
}

.filtre-vrac h2{
    width: 50px;
}


.filtre-local {
    display: flex;
    gap: 2em;
    align-items: center;
}

.filtre-local h2{
    width: 50px;
}



.filtre__h2 {
    font-family: 'modern-love', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
}

.filtre-categorie-vrac button{
    background-color: grey;
    border: none;
    border-radius: 0.5em;
    padding: 0.5em;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.filtre-categorie-vrac button:hover {
    cursor: pointer;
    background-color: #f0c8c5;
}

.filtre-categorie-vrac button.active {
    background-color: #f0c8c5;
    color: black;
    box-shadow: 0 0 0 0;
  }
  

.filtre-tous-button button {
    width: 16.5em;
}

.filtre-categorie-button {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
}
.filtre-categorie-button button {
    width: 8em;
}

.filtre-categorie-select {
    display: none;
}

//switch //

.desactive {
    opacity: 0;
}
.switch {
    position: relative;
    display: inline-block;
    width: 8em;
    height: 34px;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: grey;
    transition: .4s;
    border-radius: 2em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 1.5em;
    width: 1.5em;
    left: 0.5em;
    bottom: 0.35em;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  input:checked + .slider {
    background-color: #f0c8c5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px grey;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  input:checked + .slider:before {
    transform: translateX(5.5em);
  }
  
  @media screen and (max-width: 768px) {

    .articles-page {
        flex-direction: column;
        justify-content: center;
        gap: 7%;
        width: 99%;
    }

    .articles__div {
        width: 100%;
        justify-content: center;
    }

    .filtre-article__div {
        flex-direction: column;
        max-width: 100%;
        padding: 0;
    }
    .filtre-categorie-vrac {
        flex-direction: column;
        width: 100%;
        justify-content: start;
        align-items: center;
        margin-right: 15px;
        margin-top: 16px;
    }

    .filtre-categorie {
        flex-direction: row;
    }

    .filtre-categorie-button {
        flex-direction: row;
    }

    .filtre-tous-button button{
        width: 80px;
    }

    .filtre-categorie-button button {
        width: 80px;
    }

    .filtre-vrac {
        gap: 10px;
        margin-top: 10px;
    }

    .filtre-local {
        gap: 10px;
        margin-top: 10px;
    }

    .switch {
        width: 80px;
        height: 34px;
      }
      
    input:checked + .slider:before {
        transform: translateX(40px);
      }

    .filtre-categorie-select {
        display: initial;
    }

    .filtre-categorie-button {
        display: none;
    }
    
    .filtre-categorie-select select{
        width: 220px;
        height: 40px;
        border: none;
        font-family: 'Calibri', sans-serif;
        font-size: 1em;
        font-weight: bold;
        border: #f0c8c5 solid 0.215em;
        border-radius: 0.5em;
        text-align: center;
    }

    .filtre-tous-button button{
        width: 220px;
    }

    .filtre-categorie {
        flex-direction: column;
    }

    .filtre-vrac-local {
    margin-left: 4em;
}

    .filtre-vrac {
        gap: 1em;
        width: 220px;
    }

    .filtre-local {
        gap: 1em;
        width: 220px;
    }
}
