.accessibility {
    width: 60%;
    margin: 3em auto;
    border: solid 0.5em #f0c8c5;
    border-radius: 1em;
    padding: 1.5em;
}

.accessibility__div {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
}

.accessibility__h1 {
    font-family: 'Calibri', sans-serif;
    font-size: 1.25em;
    font-weight: bold;
    text-decoration: underline;
}

.accessibility__h2 {
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: normal;
    text-decoration: underline;
    white-space: nowrap;
}

.accessibility__p {
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
}

.accessibility-content__div {
    display: flex;
    flex-direction: column;
    gap: 0.125em;
}

@media screen and (max-width: 768px) {

    .accessibility {
        width: 70%;
    }
}

