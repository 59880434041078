.modalQuantite__h1 {
    text-align: center;
    font-family: 'modern-love', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
}

.overlay-quantite {
    position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5em;
    z-index: 100;
    animation: fadeInOverlay 0.5s ease forwards;
}

.modal-content-quantite {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    gap: 1.25em;
    width: 300px;
    height: auto;
    box-sizing: border-box;
    border: solid 0.25em #e9afaf;
    border-radius: 1.25em;
    background-color: white;
    z-index: 101;
    animation: fadeIn 0.5s ease forwards;
}

.content-label-quantite {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.content-quantite input {
    width: 120px;
    border: solid 0.125em #e6e6e6;
    margin-right: 1em;
}

.modal-quantite__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0c8c5;
    border: none;
    height: 2em;
    border-radius: 20px;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    color: white;
    padding: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.modal-quantite__button:hover {
    background-color: #e9afaf;
}