.login-container {
    width: 300px;
    margin: 0 auto;
    

}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    border: 0.125em solid #f0c8c5;
    border-radius: 1em;
    padding: 2em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.login__input {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1em;
}

.login__h1 {
    font-family: 'modern-love', sans-serif;
    font-size: 2em;
    color: #f0c8c5;
    text-align: center;
    margin: 0.25em 0;
    
}

label {
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    white-space: nowrap;
}

input {
    border: none;
    height: 2em;
    text-align: center;
}

.login-input__div {
    display: flex;
    gap: 0.5em;
}

.login__button {
    border: none;
    background-color: #f0c8c5;
    color: white;
    height: 2.5em;
    border-radius: 0.75em;
    padding: 0.5em;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}