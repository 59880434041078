.siteMap {
    width: 60%;
    margin: 3em auto;
    border: solid 0.5em #f0c8c5;
    border-radius: 1em;
    padding: 1.5em;
}

.siteMap__div {
    display: flex;
    flex-direction: column;
    gap: 0.75em;
}

.siteMap__h1 {
    font-family: 'Calibri', sans-serif;
    font-size: 1.25em;
    font-weight: bold;
    text-decoration: underline;
    white-space: nowrap;
}

.siteMap__h2 {
    font-family: 'Calibri', sans-serif;
    font-size: 0.8em;
    font-weight: normal;
    font-style: italic;
}

.siteMap__p {
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
}

.siteMap-content__div {
    display: flex;
    flex-direction: column;
    gap: 0.125em;
}

.siteMap-groupe-h {
    display: flex;
    align-items: center;
}


@media screen and (max-width: 768px) {

    .siteMap {
        width: 70%;
    }  
}

