//****** GENERAL ******//
.loading__div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.loading-icon {
    height: 50px;
    width: 50px;
}

.admin-container {
    display: flex;
    flex-direction: column;
}

.bloc-modale {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titre-bloc-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.titre-bloc {
    font-family: 'modern-love', sans-serif;
    font-size: 2em;
    text-align: center;
    margin: 0.75%;
    color: white;
    background-color: black;
    padding: 1em 0;
    border-radius: 0.5em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 1.5em;
    width: 200px;
    height: 100px;
    box-sizing: border-box;
}

.titre-bloc:hover {
background-color: #e9afaf;
}

.active {
    background-color: #e9afaf;
}

.submit__div {
    width: 100%;
    background-color: aqua;
}

.admin-submit {
    width: 250px;
    margin: 1.5em auto;
    border: none;
    color: black;
    height: 2.5em;
    border-radius: 0.75em;
    padding: 0.5em;
    font-family: 'Calibri', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: #e8e8e8;
}

.admin-submit:hover {
    background-color: #f0c8c5;
    color: white;
}

//****** ******//

//****** ModaleOuverture ******//

.admin-modaleOuverture-dropdown {
    width: 100%;
    max-width: 720px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.admin-modaleOuverture {
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    border: #e9afaf solid 0.125em;
    position: relative;
    overflow: hidden;
}

.admin-modaleOuverture__h1 {
    font-family: 'Calibri', sans-serif;
    font-size: 2.5em;
    color: #e9afaf;
    text-align: center;
    margin: 2em 0.5em 1em 0.5em;
}

.admin-modaleOuverture__title {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 1.75em;
    text-decoration: none;
    color: black;
    text-align: center;
    border:  solid #E8E8E8 0.0125em ;
    resize: none;
    overflow-y: hidden;
    margin: 0em 1em 0.5em 1em;
}

.admin-modaleOuverture__message {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 1.25em;
    text-decoration: none;
    color: black;
    text-align: center;
    border:  solid #E8E8E8 0.0125em ;
    margin: 2em 1.5em 2em 1.5em;
    resize: none;
    overflow-y: hidden;
}

.admin-close-icon {
    font-size: 1.25em;
    position: absolute;
    right: 0;
    top: 0;
    padding: 1em;
}

.admin-cloche-icon {
    color: black;
    animation: bell-ring 0.5s ease infinite alternate;
    font-size: 1.5em;
    color: #e9afaf;
    position: absolute;
    left: 50%;
    top: 0;
    padding: 1em 0;
}

.admin-modale-form {
    display: flex;
    flex-direction: column;
}

//****** ******//

//****** BANDEAU HORAIRE ******//

.admin-horaire-dropdown {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.admin-bandeau-presentation {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    border: #e9afaf solid 0.125em;
    padding: 0 2em;
}

.adresse{
    font-family: 'Calibri', sans-serif;
    font-size: 4vw;
    font-weight: bold;
    margin-top: 0.5em;
    text-align: center;
}

.admin-horaire-textarea {
    width: 70vw;
    font-family: 'Calibri', sans-serif;
    font-size: 2.5vw;
    margin-bottom: 1em;
    resize: none;
    overflow-y: hidden;
    text-align: center;
    border:  solid #E8E8E8 0.0125em ;
}

.legende-container {
    margin-top: 1.5em;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
}

//****** ******//

//****** Article ******//

.admin-article-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-article-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

.recto-article-container {
    width: 49%;
    display: flex;
    justify-content: end;
}

.recto-article {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 200px;
    min-width: 200px;
    height: 280px;
    border: #e9afaf 0.125em solid;
    border-radius: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-right: 5px;
}

.admin-article-bloc-photo {
    display: flex;
    position: relative;
    height: 140px;
}

.admin-article-addPhoto {
    background-color: #e6e6e6;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 150px;
    height: 115px;
}

.preview-image {
    width: 150px;
    height: 115px;
    object-fit: cover;
    border-radius: 0.5em;
}

.photo-label {
    position: relative;
    display: flex;
    flex-direction: column;
}

.addPhoto-icon {
    font-size: 50px;
    color: white;
    margin-bottom: 0.125em;
}

.photo-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
}

.photo-text {
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    color: grey;
}

.admin-article-vrac-local {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    left: -30px;
}

.admin-article-vrac button {
    background-image: url('./vrac-icon.webp');
    background-size: cover;
    background-color: transparent;
    width: 55px;
    height: 55px;
    padding: 0;
    border: none;
    text-indent: -9999px;
    cursor: pointer;
    filter: grayscale(100%);
}

.admin-article-local button {
    background-image: url('./local-icon.webp');
    background-size: cover;
    background-color: transparent;
    width: 55px;
    height: 55px;
    padding: 0;
    border: none;
    text-indent: -9999px;
    cursor: pointer;
    filter: grayscale(100%);
}

.admin-article-vrac button.selected,  .admin-article-local button.selected{
    filter: grayscale(0%);
}

.admin-article-nom {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
    border:  solid #E8E8E8 0.0125em ;
}

.admin-article-nom input {
    width: 97%;
    text-align: center;
}

.admin-article-prix {
    width: 80%;
    margin: 0 auto;
    border:  solid #E8E8E8 0.0125em ;
    text-align: center;
    margin-bottom: 10px;
}

.admin-article-prix input {
    width: 49px;
}

.admin-article-prix select {
    width: 29%;
    height: 100%;
    border: none;
}

.infoSupp {
    width: 30%;
    background-color: #f0c8c5;
    border-radius: 1em;
    margin: 0 auto;
    color: white;
    padding: 0.5em;
    margin-bottom: 0.5em;
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.infoSupp:hover {
    background-color: #e9afaf;
}

.verso-article-container {
    width: 49%;
    display: flex;
    justify-content: start;
}

.admin-verso-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 200px;
    min-width: 200px;
    height: 280px;
    border: #e9afaf 0.125em solid;
    border-radius: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.admin-article-add-modify-description textarea{
    width: 170px;
    height: 70px;
    text-align: center;
    border:  solid #E8E8E8 0.0125em ;
    resize: none;
    font-family: 'calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
}

.admin-article-add-modify-composition textarea{
    width: 170px;
    height: 100px;
    text-align: center;
    border:  solid #E8E8E8 0.0125em ;
    resize: none;
    font-family: 'calibri', sans-serif;
    font-size: 0.75em;
}

.admin-article-categorie-distributeur {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    margin-top: 0.5em;
}
.admin-article-categorie select {
    width: 150px;
    border: none;
    text-align: center;
}

.admin-article-distributeur select {
    width: 150px;
    border: none;
    text-align: center;
}

.admin-article-description textarea{
    width: 170px;
    height: 70px;
    text-align: center;
    border:  solid #E8E8E8 0.0125em ;
    resize: none;
    font-family: 'calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
}

.admin-article-composition textarea{
    width: 170px;
    height: 100px;
    text-align: center;
    border:  solid #E8E8E8 0.0125em ;
    resize: none;
    font-family: 'calibri', sans-serif;
    font-size: 0.75em;
    resize: none;
}

.admin-article-button-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.admin-article-button__div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
}

.article-button__div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
}

.admin-article-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 50px;
    border-radius: 1em;
    background-color: black;
    cursor: pointer;
    box-sizing: border-box;
}

.article-button-text {
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    margin-left: 0.5em;
    color: white;
}

.remise-zero {
    width: 50px;
}



.admin-article-button:hover {
    background-color: #e9afaf;
}

.article-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 1em;
    background-color: black;
    cursor: pointer;
    box-sizing: border-box;
}

.article-button:hover {
    background-color: #e9afaf;
}

.article-button-icon {
    color: white;
    font-size: 25px;
}

.admin-article-select__div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
}

.admin-article__select {
    width: 280px;
    height: 40px;
    text-align: center;
    background-color: #E8E8E8;
    border: none;
    border-radius: 4em;
    color: black;
    font-size: 1em;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1em;
}
// ARTICLE SUPPRIMER //

.article-dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.article-nom {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: start;
    font-family: 'modern-love', sans-serif;
    font-size: 1.15em;
    font-weight: bold;
}

.article-prix {
    text-align: center;
    font-family: 'calibri', sans-serif;
    font-size: 1em;
    margin-bottom: 1.25em;
}

.admin-article-categorie-distributeur {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-article-categorie {
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    font-weight: bold;
}

.admin-article-distributeur {
    font-family: 'Modern-love', sans-serif;
    font-size: 1em;
    font-weight: bold;
    margin-top: 5px;
}

.admin-article-description {
    height: 70px;
    width: 80%;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
}

.admin-article-composition {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    height: 120px;
    width: 80%;
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    border: #e6e6e6 solid 0.012em;
    border-radius: 0.5em;
    padding: 5px;
    box-sizing: border-box;
}

.admin-article-composition span{
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
}

.article-addPhoto {
    margin-top: 10px;
}

.photo-div {
    margin-left: 25px;
}

.vrac-logo {
    width: 50px;
    position: absolute;
    left: -25px;
}

.local-logo {
    width: 48px;
    position: absolute;
    left: -25px;
    top: 70px;
}
//***** CATEGORIE *****//

.bloc-categorie-fournisseur{
    width: 300px;
    margin: 0 auto;
}

.admin-categorie-fournisseur-dropdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 250px;
    border: #e9afaf solid 0.25em;
    border-radius: 1em;
    box-sizing: border-box;

}

.admin-select-input {
    padding: 15px;
    width: 250px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.admin-select-input select{
    width: 250px;
    height: 40px;
    border: #e6e6e6 solid 0.125em;
    font-weight: bold;
    text-align: center;
}

.admin-select-input input{
    height: 30px;
    text-align: center;
    padding-right: 1.25em;
    color: gray;
    border-bottom: #e6e6e6 solid 0.125em;
}

.admin-delete-select {
    width: 250px;
    height: 40px;
    text-align: center;
    font-weight: bold;
    margin-top: 25px;
    border: #e6e6e6 solid 0.125em;
}


.nouvelle-categorie-fournisseur__input {
    margin-top: 2em;
    width: 250px;
    border: #e6e6e6 solid 0.125em;
}

.info-g-ml {
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    color: gray;
    text-align: center;
    margin-top: 20px;
}

.info-vrac-local{
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    color: gray;
    text-align: center;
    margin-top: 5px;
}

// PRODUCTEUR //

.supplierCard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-producteur-container-articles {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

// PRODUCTEUR CARD //

.admin-producteur-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-producteur {
    width: 300px;
    height: 400px;
    border: solid 0.25em #f0c8c5;
    border-radius: 0.75em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    padding-bottom: 2em;
    background-color: white;
}

.admin-producteur__div {
    width: 99%;
}

.admin-producteur input {
    background-color: transparent;
}

.admin-producteur-photo-nom{
    width: 100%;

}

.admin-producteur-photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 185px;
    background-color: #e6e6e6;
    cursor: pointer;
}

.admin-producteur-photo span {
    font-size: 1em;
    cursor: pointer;
}


.admin-producteur-photo img{
    width: 100%;
    height: 180px;
    border-radius: 0.25em;
    cursor: pointer;
    object-fit: contain;
}

.admin-producteur-nom input {
    width: 98%;
    font-family: 'Modern-love', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 0;
    text-align: center;
    border: #e6e6e6 solid 0.0125em;
}

.admin-producteur-adresse textarea{
    width: 98%;
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    height: 3em;
    border: #e6e6e6 solid 0.0125em;
    resize: none;
}

.admin-producteur-site input{
    width: 99%;
    text-align: center;
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    margin: 0.5em 0;
    border: #e6e6e6 solid 0.0125em;
}

// ARTICLE CARD //

.producteur-articles {
    transform: scaleX(0);
    opacity: 0;
    width: 800px;
    height: 100px;
    border: solid 0.25em #f0c8c5;
    border-radius: 0.75em;
    padding: 0.5em;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    background-color: white;
    cursor: pointer;
}

//****** MEDIA QUERIES ******//
@media screen and (max-width: 768px) {

    .producteur-container-articles {
        flex-direction: column;
    }

    .producteur-articles {
        transform: scaleY(0);
        transform: scaleX(1);
        width: 280px;
        height: auto;
        min-height: 100px;
    }

    .extendedArticles {
        transform-origin: top;
        transform: scaleY(1);
    }

    .producteur-container:hover {
        background-color: white;
    }

    .extended:hover {
        background-color: #f0c8c5;
    }
}

// Effet Open / Close à mettre à la fin //
.open {
    padding: 1.25em;
    height: auto;
    transform: scaleX(1);
    opacity: 1;
    transition: transform 0.8s ease, opacity 0.5s ease, height 0.8s ease, padding 0.5s ease;
}

.close {
    padding: 0 1.25em;
    height: 0;
    transform: scaleX(0);
    opacity: 0;
    transition: transform 0s ease, opacity 0.2s ease, height 0.3s ease, padding 0.5s ease;
}

.openArticleButton {
    height: auto;
    opacity: 1;
    transform: scaleX(1);
    transition: transform 0.5s ease, opacity 0.5s ease, height 0.5s ease;
}

.closeArticleButton {
    height: 0;
    opacity: 0;
    transform: scaleX(0);
    transition: transform 0.5s ease, opacity 0.3s ease, height 0.5s ease;
}

.openButton {
    height: 50px;
    opacity: 1;
    transform: scaleX(1);
    transition: transform 0.5s ease, opacity 0.5s ease, height 0.5s ease;
}

.closeButton {
    height: 0;
    opacity: 0;
    transform: scaleX(0);
    transition: transform 0.5s ease, opacity 0.3s ease, height 0.5s ease;
}

.activeButton1 {
    background-color: #e9afaf;
}


.activeButton2 {
    background-color: #e9afaf;
}

.error {
    background-color: rgba(255, 0, 0, 0.2);
    z-index: 5;
}

//****** MEDIA QUERIES ******//
@media screen and (max-width: 768px) {

    .admin-modaleOuverture__h1 {
        font-size: 2em;
    }

    .admin-modaleOuverture-dropdown {
        width: 90%;
    }

    .titre-bloc-container {
        display: flex;
        flex-wrap: wrap;
    }

    .titre-bloc {
        width: 120px;
        height: 70px;
        font-size: 1.25em;
        margin: 0.5em;
    }

    .adresse {
        font-size: 5vw;
    }

    .admin-horaire-textarea {
        font-size: 3.75vw;
    }

    .admin-article-container {
        flex-direction: column;
        width: 100%;
    }

    .recto-article-container {
        width: 100%;
        justify-content: center;
    }

    .admin-article-button {
        width: 50px;
    }
    
    .article-button-text {
        display: none;
    }

    .recto-article {
        margin: 0;
    }

    .verso-article-container {
        width: 100%;
        justify-content: center;
        margin-top: 1em;
    }

    .remise-zero:hover {
        background-color: black;
    }

    .article-button:hover {
        background-color: black;
    }
}