.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    animation: fadeInOverlay 0.5s ease forwards;
}

.modal-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
}

.close-button {
    position: absolute;
    right: 1.25em;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.close-icon {
    font-size: 1.25em;
}

.close-icon:hover {
    color: #e9afaf;
}

.modal-content {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    gap: 1.25em;
    min-width: 15.625em;
    width: 60%;
    height: auto;
    box-sizing: border-box;
    border: solid 0.25em #e9afaf;
    border-radius: 1.25em;
    background-color: white;
    z-index: 101;
    animation: fadeIn 0.5s ease forwards;
    max-height: 70%;
    overflow-y: auto;
}

.contact__h1{
    font-size: 1.75em;
    color: #e9afaf;
    margin-bottom: 0.5em;
}

.contact__p {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 1.25em;
    text-decoration: none;
    color: black;
    text-align: center;
    white-space: nowrap;
}

.contact__div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.25em;
    border-top: #e9afaf solid 0.125em;
    border-bottom: #e9afaf solid 0.125em;
}

.contact-reseau__div {
    display: flex;
    gap: 1.25em;
}

.icon-modal {
    color: black;
}

.icon-modal:hover {
    color: #e9afaf;
}

//animation modale //

.closing {
    animation: fadeOut 0.3s ease forwards;
}

.overlay.closing {
    animation: fadeOutOverlay 0.3s ease forwards;
}

@media screen and (max-width: 768px) {
.modal-content {
    width: 90%;
}
}

@keyframes fadeIn {
    from {
      opacity: 0;
      top: 20%;
    }

    to {
      opacity: 1;
      top: 40%;
    }
}

@keyframes fadeOut {
    from {
      opacity: 1;
      top: 40%;
    }
    to {
      opacity: 0;
      top: 20%;
    }
}

@keyframes fadeInOverlay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeOutOverlay {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}