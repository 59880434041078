.NotFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    margin: 4em 0;
}

.NotFound__p {
    color: #f0c8c5;
    font-family: 'Calibri', sans-serif;
    font-size: 1.875em;
    text-align: center;
    margin: 0 1.875em;
}

.NotFound__img {
    width: 50%;
    height: auto;
}

.NotFound a {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 2em;
}

.NotFound a:hover {
    color: #f0c8c5;
}


@media screen and (max-width: 768px) {

    .NotFound__p {
        font-size: 1em;
        margin: 0 3em;
    }

    .NotFound a {

        font-size: 1.25em;
    }
    
}

