.confidentiality {
    width: 50%;
    margin: 3em auto;
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    border: solid 0.5em #f0c8c5;
    border-radius: 1em;
    padding: 1.5em;
}


.confidentiality__h1 {
    font-family: 'Calibri', sans-serif;
    font-size: 1.25em;
    font-weight: bold;
    text-decoration: underline;
}


.confidentiality__p {
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
}


@media screen and (max-width: 768px) {

    .confidentiality {
        width: 70%;
    }

}

