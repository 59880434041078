.article-content-recto {
    width: 200px;
    height: 280px;
    border: #e9afaf 0.125em solid;
    border-radius: 1em;
    box-sizing: border-box;
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.photo-vrac-local {
    position: relative;
    display: flex;
    justify-content: center;
    height: 140px;
}

.article-photo {
    margin-top: 10px;
}
.article-photo img{
    width: 150px;
    height: 115px;
    object-fit: cover;
    border-radius: 0.5em;
}

.article-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 140px;
}

.article {
    width: 200px;
    height: 280px;
    position: relative;
}

.verso-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 200px;
    min-width: 200px;
    height: 280px;
    border: #e9afaf 0.125em solid;
    border-radius: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    position: absolute;
    background-color: white;
}

.article-categorie-distributeur {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backarrow-icon {
    position: absolute;
    left: 5px;
    top: 10px;
    font-size: 25px;
    color: #f0c8c5;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
    cursor: pointer;
}

.backarrow-icon:hover {
color: #e9afaf;
}

.article-categorie {
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    font-weight: bold;
}

.article-distributeur {
    font-family: 'Modern-love', sans-serif;
    font-size: 1em;
    font-weight: bold;
    margin-top: 5px;
}

.article-description {
    height: 70px;
    width: 80%;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
}

.article-composition {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    height: 120px;
    width: 80%;
    font-family: 'Calibri', sans-serif;
    font-size: 0.75em;
    border: #e6e6e6 solid 0.012em;
    border-radius: 0.5em;
    padding: 5px;
    box-sizing: border-box;
}

.article-composition span{
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
}

.shopping__div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    border: #f0c8c5 solid 0.125em;
    filter: opacity(0.75);
    font-size: 1.5em;
    color: #e9afaf;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.shopping__div:hover {
    filter: opacity(1);
    background-color: #f0c8c5;
    color: white;
    border: #e9afaf solid 0.125em;
}
// Anim Card //

.article-content-recto, .verso-article {
    transition: transform 0.6s ease, opacity 0.3s ease-out;
  }
  
  .article-content-recto {
    transform: rotateY(-180deg);
    z-index: 4;
    opacity: 0;
  }
  
  .verso-article {
    transform: rotateY(180deg);
    opacity: 0;
  }
  
  .article-content-recto.anim {
    transform: rotateY(0deg);
    opacity: 1;
  }
  
  .verso-article.anim {
    transform: rotateY(0deg);
    z-index: 4;
    opacity: 1;
  }

// pagination //

.pagination {
    list-style: none;
    display: flex;
    gap: 1em;
}
  
.pagination button {
    border: none;
    background-color: white;
    cursor: pointer;
    border-radius: 0.5em;
    width: 2em;
    height: 2em;
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 1em;
    border: #f0c8c5 solid 0.125em;
}
  
.pagination button:hover {
    background-color: grey;
}

.pagination button.active {
    background-color: #f0c8c5; 
    color: black;
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 1em;
}

// animation //

@keyframes fadeInArticles {
    0% { opacity: 0; transform: scale(0.5); }
    50% { transform: scale(1.05); }
    100% { opacity: 1; transform: scale(1); }
  }
  
  .fadeInAnimation {
    animation: fadeInArticles 0.8s ease-in-out;
  }
