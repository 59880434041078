.modaleOuverture {
    position: absolute;
}

.modaleOuverture__h1 {
    font-family: 'Calibri', sans-serif;
    font-size: 2.25em;
    color: #e9afaf;
    margin-bottom: 0.5em;
    text-align: center;
}
  
.modaleOuverture__h2 {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 1.75em;
    text-decoration: none;
    color: black;
    text-align: center;
    margin: 0em 0.5em 0.5em 0.5em;
}

.modaleOuverture-content {
    width: 90%;
    padding-bottom: 1.25em;
}


.modaleOuverture__p {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 1.25em;
    text-decoration: none;
    color: black;
    text-align: center;
    white-space: pre-wrap;
}

.youtube-thumbnail {
    text-align: center;
    margin-top: 1em;
  }
  
  .youtube-thumbnail img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  


@media screen and (max-width: 768px) {
    .modaleOuverture-content {
        width: 100%;
    }

    .modaleOuverture__h1 {
        font-size: 2em;
    }

    .modaleOuverture__h2 {
    font-size: 1.5em;
    }
}

// cloche //

.cloche-icon {
    z-index: 4;
    color: black;
    animation: bell-ring 0.5s ease infinite alternate;
    font-size: 1.5em;
    color: #e9afaf;
}

@keyframes bell-ring {
    from {
      transform: rotate(15deg);
    }
    to {
      transform: rotate(-15deg);
    }
}
