.footer {
    width: 80%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1.875em;
    margin: 4em auto;

}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.h-footer {
    font-family: 'Calibri', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
}

.footer__div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25em;
}

.footer-content__div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    font-style: 'Calibri', sans-serif;
    font-size: 1em;
}

.icon-footer {
    color: black;
}

.icon-footer:hover {
    color: #f0c8c5;
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;    
    }

}