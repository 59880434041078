.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    margin: 0 auto;
    margin-top: 3.125em;
    margin-bottom: 2em;
}

.navigation {
    display: flex;
    gap: 1em;
    font-family: 'Calibri', sans-serif;
    font-size: 1.25em;
    font-weight: bold;
}

.LinkNoStyle {
    text-decoration: none;
    color: black;
}

.LinkNoStyle:hover {
    color: #f0c8c5;
}

.contact:hover {
    cursor: pointer;
    color: #f0c8c5;
}

.header-dropdown {
    cursor: pointer;
    position: relative;
}

.header__h1 {
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
}

.epicerie-h1:hover {
    color: #f0c8c5;

}

.epicerie-dropdown-content {
    position: absolute;
    font-size: 0.8em;
    cursor: pointer;
    white-space: nowrap;
    top: 100%;
    transform: translateX(-50%) scaleY(0);
    transform-origin: top;
    transition: transform 0.2s ease;
    text-align: center;
    border-radius: 0.3125em;
    display: flex;
    padding: 1em;
    width: 22em;
    flex-direction: row;
    background-color: #f0c8c5;
    gap: 1.5em;
    justify-content: center;
    left: -0.6em;
}

.epicerie-dropdown-content .LinkNoStyle:hover {
    color: white;
}

.produits-dropdown-content {
    position: absolute;
    font-size: 0.8em;
    cursor: pointer;
    white-space: nowrap;
    top: 100%;
    transform: translateX(-50%) scaleY(0);
    transform-origin: top;
    transition: transform 0.2s ease;
    text-align: center;
    border-radius: 0.3125em;
    display: flex;
    padding: 1em;
    width: 22em;
    flex-direction: row;
    background-color: #f0c8c5;
    gap: 1.5em;
    justify-content: center;
    left: 5em;
}

.produits-dropdown-content .LinkNoStyle:hover {
    color: white;
}

.dropdown-option:hover {
    color: white;
}

.show-dropdown {
    transform: translateX(-50%) scaleY(1);
}

.dropdown-option {
    color: black;
}

.logo img {
    width: 20%;
    min-width: 18.75em
}

.admin-bandeau {
    display: flex;
    align-items: center;
    justify-content:space-between;
    background-color: black;
    font-family: 'Calibri', sans-serif;
    color: white;
    text-align: center;
    padding: 1em;
    font-size: 1em;
    height: 2em;
}

.admin-bandeau a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 134px;
    color: black;
    text-decoration: none;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    padding: 0 0.5em;
    background-color: white;
    border-radius: 0.25em;
    cursor: pointer;
    box-sizing: border-box;
}

.admin-bandeau a:hover {
    color: white;
    background-color: #f0c8c5;
}

.logout__button {
    width: 134px;
    background-color: white;
    border: none;
    padding: 0.5em 1em;
    font-family: 'Calibri', sans-serif;
    font-size: 1em;
    font-weight: bold;
    border-radius: 0.25em;
    white-space: nowrap;
    cursor: pointer;
}

.logout__button:hover {
    color: white;
    background-color: #f0c8c5;
}




// barre de recherche non opérationnelle //
.search-bar {
    display: none;
}

@media screen and (max-width: 768px) {
    
    .header {
        width: 100%;
        flex-direction: column;
        margin-top: 1.5em;
        margin-bottom: 3.5em;
        justify-content: center;
        align-items: center;
    }

    .navigation {
        width: 100%;
        justify-content: center;
        margin-top: 1em;
        gap: 0.5em;
    }

    .dropdown-content {
        width: 100vw;
        left: 120%;
    }

    .logo {
        margin: 0 auto;
    }

    .produits-dropdown-content {
        width: 288px;
        gap: 1em;
        font-size: 0.75em;
    }
    .epicerie-dropdown-content {
        width: 288px;
        gap: 1em;
        font-size: 0.75em;
        left: -0.3em;
    }

    .bandeau-adresse-horaire {
        margin-bottom: 0;
    }
}