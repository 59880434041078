.EnConstruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    margin: 3em 0;
}

.EnConstruction__p {
    color: #f0c8c5;
    font-family: 'Calibri', sans-serif;
    font-size: 1.875em;
    text-align: center;
    margin: 0 1.875em
}

.EnConstruction__img {
    width: 50%;
    height: auto;
    margin-top: 1em;
}

.EnConstruction a {
    font-family: 'Calibri', sans-serif;
    font-weight: bold;
    font-size: 2em;
}

.EnConstruction a:hover {
    color: #f0c8c5;
}


@media screen and (max-width: 768px) {

    .EnConstruction__p {
        font-size: 1em;
        margin: 0 3em;
    }

    .EnConstruction a {
        font-size: 1.25em;
    }

}

