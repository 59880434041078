.vracTuto-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border-radius: 1.5em;
    gap: 0.75em;
    margin: 1.875em 0;
    border-top: solid 0.25em #f0c8c5;
    border-bottom: solid 0.25em #f0c8c5;

}

.case-tuto {
    width: 20%;
    min-width: 11.875em;
    position: relative;
    display: flex;
    justify-content: center;
}

.case-tuto p {
    position: absolute;
    top: 1.25em;
    font-family: 'Calibri', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
}

.tuto-cursive {
    font-family: "modern-love", sans-serif;
    font-weight: bold;
    font-size: 1em;
}

.case-tuto img {
    width: 18em;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .vracTuto-container {
        border: none;
        align-items: center;
        flex-direction: column;
    }

    .case-tuto p {
        font-size: 1em;
    }

    .case-tuto img {
        width: 12em;
        object-fit: contain;
    }

}