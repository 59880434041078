.modale-liste-course {
    position: absolute;
}

.modaleListeCourse__h1 {
    font-family: 'Calibri', sans-serif;
    font-size: 2.25em;
    color: #e9afaf;
    margin-bottom: 0.5em;
    text-align: center;
}

.modaleListeCourse-content {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    gap: 1.25em;
    min-width: 18em;
    height: auto;
    box-sizing: border-box;
    border: solid 0.25em #e9afaf;
    border-radius: 1.25em;
    background-color: white;
    z-index: 101;
    animation: fadeIn 0.5s ease forwards;
    font-family: 'Calibri', sans-serif;
    font-size: 1.25em;
    font-weight: bold;

}

.modaleListeCourse-content li:hover {
    text-decoration: underline;
    color: #e9afaf;
}

.remove-item-button {
    background-color: white;
    border: none;
    padding: 0 1em;
    font-size: 1em;
    cursor: pointer;
}

.remove-item-button:hover {
    color: #e9afaf;
}

@media screen and (max-width: 768px) {
    .modaleListeCourse-content {
        font-size: 1em;
    }
    .modaleListeCourse__h1{
        font-size: 1.25em;
    }
}
