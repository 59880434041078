.presentation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.125em;
}

.presentation-logo {
    margin: 3.125em auto;
    width: 80%;
}

.bandeau-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.bandeau-image {
    width: 100%;
    border-radius: 1.875em;
}

.bandeau-adresse-horaire {
    position: absolute;
    width: 100%;
    max-height: 19.6875em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 1em;
}

.bandeau-adresse-horaire img{
    width: 30%;
    padding-right: 1.25em;
}

.bandeau-adresse-horaire-texte {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0em;
    background-color: rgba(255, 255, 255, 0.7);
}

.bandeau-adresse-horaire-texte p.adresse {
    font-family: 'Calibri', sans-serif;
    font-size: 2.5em;
    font-weight: bold;
    margin-top: 0em;
}

.bandeau-adresse-horaire-texte p.horaire {
    font-family: 'Calibri', sans-serif;
    font-size: 1.5em;
    margin-bottom: 0.125em;
    white-space: pre-wrap;
    text-align: center;
}

.bold {
    font-weight: bold;
}
.horaire {
    white-space: nowrap;
}

@media screen and (max-width: 768px) {

    .presentation-container {
        margin-bottom: 0.5em;
    }
    
    .presentation-logo {
        margin: 0em auto;
    }
    
    .bandeau-image {
        border-radius: 0vw;
        margin-top: 0.5em;
    }

    .bandeau-adresse-horaire-texte {
        margin-bottom: 0.5em;
    }
    .bandeau-adresse-horaire-texte p.adresse {
        margin-top: 0.1em;
        font-size: 5vw;
    }

    .bandeau-adresse-horaire-texte p.horaire {
        margin-bottom: 0.5em;
        font-size: 3.75vw;
    }

    .bandeau-adresse-horaire img{
        width: 40%;
        padding-right: 0.125em;
    }
    
}